<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <modal ref="bookingSuccessModal">
      <template v-slot:header>
        <center>
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_Vwcw5D.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px; margin-bottom: -85px"
            loop
            autoplay
          ></lottie-player>
        </center>
        <h1 class="pt-4 pb-2 font-semibold text-3xl text-center text-green-500">
          THANK YOU FOR YOUR BOOKING
        </h1>
        <p class="text-center text-gray-500">
          We've received your booking request. We cant wait to welcome you to
          the Maldives. We have sent you a confirmation email with further
          instructions.
        </p>
      </template>

      <template v-slot:body>
        <h1 class="pt-2 pb-12 text-2xl text-center text-green-700">
          Start packing your bags!
        </h1>
      </template>

      <template v-slot:footer>
        <div class="flex text-center items-center justify-center">
          <button
            class="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700 transition ease-in-out duration-150"
            @click="$refs.bookingSuccessModal.closeModal()"
          >
            CLOSE
          </button>
        </div>
      </template>
    </modal>

    <div class="flex md:flex-row mx-2 flex-wrap">
      <div class="w-full md:w-1/2 bg-white p-4 text-center text-gray-200">
        <h2
          class="text-2xl text-left text-black tracking-wide font-semibold leading-loose mb-2"
        >
          Booking Form
        </h2>

        <div
          v-if="bookingErrors.length > 0"
          class="text-red-500 text-left p-6 bg-red-200 border border-red-400"
        >
          <h3 class="text-xl font-bold">Errors in form.</h3>
          <small class="text-sm"
            >Please correct the below error(s) in the booking form.</small
          >
          <ul class="mt-4 list-disc ml-6">
            <li v-for="(e, i) in bookingErrors" :key="i">{{ e.error }}</li>
          </ul>
        </div>

        <div class="relative">
          <!-- Booking Form-->
          <div
            :class="{ hidden: formState !== 'accomodation' }"
            name="section1"
          >
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark"
                      >
                        <path
                          d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Accommodation
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-green-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-gray-500 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                    >
                      Add-ons
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-gray-500 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Select Accommodation
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full flex-1 mx-2">
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <search-drop-down
                          ref="propertyInput"
                          v-model="property"
                          :options="properties"
                          placeholder="Accommodation"
                        />
                      </div>
                    </div>
                    <div class="w-full flex-1 mx-2">
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <search-drop-down
                          ref="roomTypeInput"
                          v-model="roomType"
                          placeholder="Room Type"
                          :options="roomTypes"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Check-in
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="arrivalDate"
                          type="date"
                          ref="arrivalDateInput"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Check-out
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="departureDate"
                          ref="departureDateInput"
                          type="date"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click.prevent="formState = 'yourdetails'"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Break -->

          <div :class="{ hidden: formState !== 'yourdetails' }" name="section2">
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark"
                      >
                        <path
                          d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Accommodation
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-green-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Add-ons
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-gray-500 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Full Name
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full flex-1 mx-2">
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="name"
                          ref="nameInput"
                          placeholder="Your full name here"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Your Email
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="emailInput"
                          v-model="email"
                          type="email"
                          placeholder="jhon@doe.com"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Contact No.
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="phoneInput"
                          v-model="phone"
                          placeholder="Just a hint.."
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        No. of Rooms
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="numberOfRoomsInput"
                          v-model="numberOfRooms"
                          type="number"
                          placeholder="Just a hint.."
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Type of Holiday
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="holidayTypeInput"
                          v-model="holidayType"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Adults
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="adults"
                          type="number"
                          ref="adultsInput"
                          placeholder="No. of Adults"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Child (0-12 YRS)
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="children"
                          type="number"
                          placeholder="No. of Children"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Nationality
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="nationality"
                          ref="nationalityInput"
                          type="text"
                          placeholder="Nationality"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1"></div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Your Requirements
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <textarea
                          v-model="requirements"
                          type="text"
                          placeholder="Write us any additional information about your holiday. Tell us more about your wishes, budget or anything you would like us to know"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <button
                    @click.prevent="formState = 'accomodation'"
                    class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
                  >
                    Previous
                  </button>
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click.prevent="formState = 'addons'"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Break -->

          <div :class="{ hidden: formState !== 'addons' }" name="section3">
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark"
                      >
                        <path
                          d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Accommodation
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 bg-green-600 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Add-ons
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-green-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3 mb-3"
                  >
                    Select your add-ons
                  </div>
                  <div class="flex flex-col-2 ">
                    <div
                      v-for="addOn in filteredAddOns"
                      :key="addOn._id"
                      class="card flex flex-col justify-center p-10 bg-white rounded-lg shadow-2xl mx-2 my-2"
                    >
                      <div class="addon-title">
                        <p
                          class="text-xl uppercase text-gray-900 font-bold mb-4"
                        >
                          {{ addOn.name }}
                        </p>
                        <!-- <p class="uppercase text-sm text-gray-400">
                          Description
                        </p> -->
                      </div>
                      <div class="addon-img">
                        <img
                          :src="
                            'https://cms.maldivesholidays.com/uploads/' +
                              addOn.featuredImage
                          "
                          class="block"
                        />
                      </div>
                      <div class="addon-info grid gap-10">
                        <div
                          class="flex flex-col md:flex-row justify-between items-center pt-8 text-gray-900"
                        >
                          <p class="font-bold text-m">${{ addOn.price }}</p>
                          <button
                            @click="addOns = [...addOns, addOn]"
                            class="flex ml-4 px-2 py-2 text-right text-m transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-activity"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="addOn in selectedAddOns"
                      :key="addOn._id"
                      class="card flex flex-col justify-center p-10 bg-gray-100 rounded-lg shadow-2xl mx-2 my-2"
                    >
                      <img
                        :src="
                          'https://cms.maldivesholidays.com/uploads/' + addOn.featuredImage
                        "
                        class="block"
                      />

                      <div class="addon-title">
                        <p class="text-xl uppercase text-gray-900 font-bold">
                          {{ addOn.name }}
                        </p>
                        <!-- <p class="uppercase text-sm text-gray-400">
                          Description
                        </p> -->
                      </div>
                      <div class="addon-img">
                        <img src="" class="w-full object-cover object-center" />
                      </div>
                      <div class="addon-info grid gap-10">
                        <div
                          class="flex flex-col md:flex-row justify-between items-center pt-8 text-gray-900"
                        >
                          <p class="font-bold text-m">${{ addOn.price }}</p>
                          <button
                            disabled
                            class="cursor-not-allowed flex ml-4 px-2 py-2 text-right text-m transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-activity"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <button
                    @click.prevent="formState = 'yourdetails'"
                    class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
                  >
                    Previous
                  </button>
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click.prevent="formState = 'confirm'"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Break -->

          <div :class="{ hidden: formState !== 'confirm' }" name="section4">
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark"
                      >
                        <path
                          d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Accommodation
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 bg-green-600 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Add-ons
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Confirmation Section
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <button
                    @click.prevent="formState = 'addons'"
                    class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
                  >
                    Previous
                  </button>

                  <div class="flex-auto flex flex-row-reverse">
                    <vue-recaptcha
                      ref="recaptcha"
                      sitekey="6LcTbmoaAAAAACiDVjlPM59hgMeJkNn-Jzd8PSrd"
                    >
                      <button
                        @click="submitBooking()"
                        class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                      >
                        Submit
                      </button>
                    </vue-recaptcha>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  Booking Form -->

          <div class="text-left mt-2 text-black">
            <span> </span>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 p-4 text-center text-gray-700">
        <h2
          class="text-2xl text-right text-black tracking-wide font-semibold leading-loose mb-2"
        >
          Your Booking Details
        </h2>
        <!-- PLACEHOLDER -->
        <room-card
          v-if="roomType"
          :displayOnly="true"
          :showAmenities="true"
          :property="property"
          :roomType="roomType"
        ></room-card>
        <div class="trans-all">
          <div class="w-full lg:flex mb-5">
            <div
              v-if="$store.state.booking.specialOffer.name"
              class="w-full uppercase border-yellow-500 bg-yellow-100 p-8 border-r border-b border-l border-blue-light lg:border-l-1 lg:border-t lg:border-blue-light bg-white p-4 flex flex-col text-left justify-between leading-normal"
            >
              SPECIAL OFFER {{ $store.state.booking.specialOffer.name }} FOR
              {{ $store.state.booking.property.name }}
              <!-- <room-card
                v-for="roomType in property.roomTypes"
                :key="roomType._id"
                :property="property"
                :roomType="roomType"
              ></room-card> -->

              <!-- <div class="mb-0">
                <span>
                  Room card will be displayed above, similar to single
                  accommodation page. Both property and room type will be
                  displayed on it. Any other detail that might be missing please
                  display in this div and the design will be amended.
                </span>
              </div> -->
            </div>
          </div>

          <div
            v-for="addOn in addOns"
            :key="addOn._id"
            class="w-full lg:flex mb-5"
          >
            <div
              class="w-full p-8 border-r border-b border-l border-blue-light lg:border-l-1 lg:border-t lg:border-blue-light bg-white p-4 flex flex-col justify-between leading-normal"
            >
              <div class="mb-0">
                <div
                  class="flex md:flex-row inline-block items-center justify-left border-gray-300 text-gray-700"
                >
                  <div class="text-black font-bold text-lg text-left">
                    {{ addOn.name }}

                    <div class="text-black font-light text-sm text-left">
                      ${{ addOn.price }}
                    </div>
                  </div>
                  <div class="flex-1 inline-flex text-black justify-end">
                    <a
                      class="flex border border-gray-300 text-gray-700 rounded-md px-4 py-2 mr-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                      target="_blank"
                      :href="
                        'https://cms.maldivesholidays.com/uploads/' + addOn.pdf
                      "
                      >Terms & Conditions PDF</a
                    >

                    <button
                      @click="addOns = addOns.filter(a => a._id !== addOn._id)"
                      type="button"
                      class="flex border border-gray-300 text-gray-700 rounded-md px-4 py-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="pr-2 feather feather-activity"
                      >
                        <path
                          d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
                        ></path>
                        <line x1="18" y1="9" x2="12" y2="15"></line>
                        <line x1="12" y1="9" x2="18" y2="15"></line>
                      </svg>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-6">
      <div class="flex flex-wrap">
        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3309285-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Luxury Maldives Experience
              </h2>
              <p class="text-sm">
                We can guarentee peace of mind when booking with us
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3533484-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Travel Insurance Protection
              </h2>
              <p class="text-sm">
                Our holiday packages are financially protected by Travel
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3188775-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Flexible Payments
              </h2>
              <p class="text-sm">
                Spread the cost of your luxury holiday with monthly payments
                with easy online payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both; display: block; height: 0px"></div>

    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueRecaptcha from "vue-recaptcha";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import Modal from "@/components/others/Modal";
import SearchDropDown from "@/components/others/SearchDropDown";
// import SearchBar from "../others/SearchBar.vue";
import RoomCard from "@/components/others/RoomCard";
// import { createSlides } from "../others/slides.js";

export default {
  name: "BookingPage",
  data() {
    return {
      formState: this.$route.query.formstate || "accomodation",
      properties: [],
      roomTypes: [],
      availableAddOns: [],
      bookingErrors: [],
      showModal: false
    };
  },

  mounted() {
    this.getProperties();
    if (this.property !== "") {
      this.getAddOns(this.property._id);
    }
  },
  watch: {
    property(value) {
      this.roomType = "";
      this.getRoomTypes(value._id);
      this.getAddOns(value._id);
    }
  },
  // eslint-disable-next-line
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_BOOKING_FIELD", {
      field: "specialOffer",
      value: { name: "", percentage: "" }
    });
    next();
  },
  methods: {
    validated() {
      const inputRefs = [
        "nameInput",
        "phoneInput",
        "adultsInput",
        "numberOfRoomsInput",
        "holidayTypeInput",
        "propertyInput",
        "roomTypeInput",
        "arrivalDateInput",
        "departureDateInput",
        "emailInput",
        "nationalityInput"
      ];

      inputRefs.forEach(r => {
        if (this.$refs[r].$el !== undefined) {
          // console.log(this.$refs[r].$el);
          this.$refs[r].$el.style.backgroundColor = "#fff";
          this.$refs[r].$el.children[0].style.backgroundColor = "#fff";
          this.$refs[r].$el.parentElement.style.backgroundColor = "#fff";
        } else {
          this.$refs[r].parentElement.style.backgroundColor = "#fff";
          this.$refs[r].style.backgroundColor = "#fff";
        }
      });
      this.bookingErrors = [];
      console.log(this.bookingErrors);
      console.log("Hello");
      // Validate detail fields
      if (!this.name) {
        this.bookingErrors.push({
          ref: "nameInput",
          error: "Name is required."
        });
      }

      if (!this.phone) {
        this.bookingErrors.push({
          ref: "phoneInput",
          error: "Phone number is required."
        });
      }

      if (this.adults < 1) {
        this.bookingErrors.push({
          ref: "adultsInput",
          error: "Minimum one adult is required."
        });
      }

      if (this.numberOfRooms < 1) {
        this.bookingErrors.push({
          ref: "numberOfRoomsInput",
          error: "Minimum one room is required."
        });
      }

      if (!this.holidayType) {
        this.bookingErrors.push({
          ref: "holidayTypeInput",
          error: "Holiday type is required."
        });
      }
      // Validate Property and room type fields
      if (this.property === "") {
        this.bookingErrors.push({
          ref: "propertyInput",
          error: "Accomodation is required."
        });
      }

      if (this.roomType === "") {
        this.bookingErrors.push({
          ref: "roomTypeInput",
          error: "Room Type is required."
        });
      }
      // Validate the checkin and checkout date fields
      if (!this.arrivalDate) {
        this.bookingErrors.push({
          ref: "arrivalDateInput",
          error: "Arrival Date is required."
        });
      }
      if (!this.departureDate) {
        this.bookingErrors.push({
          ref: "departureDateInput",
          error: "Arrival Date is required."
        });
      }
      const difference = moment(this.departureDate).diff(
        moment(this.arrivalDate),
        "days"
      );
      if (difference < 0) {
        this.bookingErrors.push({
          ref: "arrivalDateInput",
          error: "Invalid Checkin/Checkout Dates"
        });
        this.bookingErrors.push({
          ref: "departureDateInput",
          error: "Checkout date cannot be before checkin date."
        });
      }
      // Validate the email input
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailValidated = re.test(String(this.email).toLowerCase());

      if (!emailValidated) {
        this.bookingErrors.push({ ref: "emailInput", error: "Invalid email." });
      }

      //Validate the nationality input
      if (!this.nationality) {
        this.bookingErrors.push({
          ref: "nationalityInput",
          error: "Nationality required."
        });
      }

      if (this.bookingErrors.length === 0) {
        return true;
      } else {
        // Add error indicators to UI
        this.bookingErrors.forEach(e => {
          if (this.$refs[e.ref].$el !== undefined) {
            // console.log(this.$refs[e.ref].$el);
            this.$refs[e.ref].$el.style.backgroundColor = "#ffc9d4";
            this.$refs[e.ref].$el.children[0].style.backgroundColor = "#ffc9d4";
            this.$refs[e.ref].$el.parentElement.style.backgroundColor =
              "#ffc9d4";
          } else {
            this.$refs[e.ref].parentElement.style.backgroundColor = "#ffc9d4";
            this.$refs[e.ref].style.backgroundColor = "#ffc9d4";
          }
        });

        return false;
      }
    },
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties?getAll=true`
        );
        this.properties = response.data.properties;
      } catch (e) {
        console.log(e);
      }
    },
    async getAddOns(id) {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties/${id}/addOns`
        );
        this.availableAddOns = response.data.addOns;
      } catch (e) {
        console.log(e);
      }
    },
    async addAddOn() {
      try {
        this.newAddOn.pdfFile = await this.uploadPdf(this.$refs.pdfInput.files);
        this.newAddOn.featuredImage = await this.uploadImage(
          this.$refs.featuredImageInput.files
        );
        const response = await this.$axios.post(
          `${this.$customConfig.api_host}/api/v1/properties/${this.$route.params.propertyid}/addOns`,
          {
            name: this.newAddOn.name,
            price: this.newAddOn.price,
            featuredImage: this.newAddOn.featuredImage,
            pdfFile: this.newAddOn.pdfFile
          },
          {
            headers: {
              authorization: `bearer ${this.$store.state.auth.token}`
            }
          }
        );

        if (response.status === 201) {
          this.clearForm();
          this.getAddOns();
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getRoomTypes(id) {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties/${id}/roomTypes`
        );
        this.roomTypes = response.data.roomTypes;
      } catch (e) {
        console.log(e);
      }
    },
    async submitBooking() {
      try {
        const validated = this.validated();

        if (!validated) {
          this.formState = "accomodation";
          return;
        }

        const booking = { ...this.$store.state.booking };

        booking.property = booking.property._id;
        (booking.roomType = booking.roomType._id), console.log(booking);
        const response = await axios.post(
          `${this.$customConfig.api_host}/api/v1/bookings`,
          booking
        );
        if (response.status === 201) {
          this.$refs.bookingSuccessModal.openModal();
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    property: {
      get() {
        return this.$store.state.booking.property;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", { field: "property", value });
      }
    },
    roomType: {
      get() {
        return this.$store.state.booking.roomType;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", { field: "roomType", value });
      }
    },
    arrivalDate: {
      get() {
        return this.$store.state.booking.arrivalDate;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "arrivalDate",
          value
        });
      }
    },
    departureDate: {
      get() {
        return this.$store.state.booking.departureDate;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "departureDate",
          value
        });
      }
    },
    name: {
      get() {
        return this.$store.state.booking.name;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "name",
          value
        });
      }
    },
    email: {
      get() {
        return this.$store.state.booking.email;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "email",
          value
        });
      }
    },
    phone: {
      get() {
        return this.$store.state.booking.phone;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "phone",
          value
        });
      }
    },
    numberOfRooms: {
      get() {
        return this.$store.state.booking.numberOfRooms;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "numberOfRooms",
          value
        });
      }
    },
    holidayType: {
      get() {
        return this.$store.state.booking.holidayType;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "holidayType",
          value
        });
      }
    },
    adults: {
      get() {
        return this.$store.state.booking.adults;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "adults",
          value
        });
      }
    },
    children: {
      get() {
        return this.$store.state.booking.children;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "children",
          value
        });
      }
    },
    nationality: {
      get() {
        return this.$store.state.booking.nationality;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "nationality",
          value
        });
      }
    },
    requirements: {
      get() {
        return this.$store.state.booking.requirements;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "requirements",
          value
        });
      }
    },
    addOns: {
      get() {
        return this.$store.state.booking.addOns;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "addOns",
          value
        });
      }
    },
    filteredAddOns() {
      return this.availableAddOns.filter(
        addOn => !this.addOns.some(a => a._id === addOn._id)
      );
    },
    selectedAddOns() {
      return this.availableAddOns.filter(addOn =>
        this.addOns.some(a => a._id === addOn._id)
      );
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchDropDown,
    Modal,
    RoomCard,
    VueRecaptcha
  }
};
</script>

<style scoped>
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
           WDM
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

/*each image is referenced twice in the HTML, but each image only needs to be updated in the CSS*/
.image1 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e73d01476.jpg");
}

.image2 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e35541455.jpg");
}

.image3 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e5763146a.jpg");
}

.image4 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070eaec71491.jpg");
}

.image5 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070ebff014a0.jpg");
}

.transall {
  transition: all 0.5s;
}
</style>
